import React, { useRef, useEffect } from 'react'

import { Portal } from "../portal/portal"
import FocusTrap from "../../hooks/focusTrap"
import { useKeyPress } from "../../hooks/useKeyPress"
import closeIcon from "../../../theme/icons/close.svg"
import TcbColorSquares from "../tcbColorSquares"

import Icon from '../icon'





const Modal = ({open, onClose, children}) => {

  useKeyPress('Escape', () => {
    onClose();
  });

  if(!open) return null

  return (
    <Portal wrapperId="modal">
      <div onClick={onClose} css={{
        display: `grid`, 
        overflowY: `auto`, 
        overflowX: `hidden`, 
        position: `fixed`, 
        top: `0`, 
        left: `0`, 
        zIndex: `40`, 
        alignContent: `center`, 
        outlineStyle: `none`, 
        width: `100%`, 
        height: `100%`, 
        justifyContent: `center`,
        backgroundColor: `rgba(0, 0, 0, 0.6)`

      }}>
        <FocusTrap>
          <div role="dialog" aria-modal="true" aria-describedby='modalDescription' onClick={e => e.stopPropagation()} css={{
            zIndex: `50`, 
            maxWidth: `48rem`, 
            backgroundColor: `#ffffff`, 
            placeSelf: `center`
          }}>
            <div css={{
              position: `absolute`,
              width: `1px`,
              height: `1px`,
              padding: `0`,
              margin: `-1px`,
              overflow: `hidden`,
              clip: `rect(0, 0, 0, 0)`,
              whiteSpace: `nowrap`,
              borderWidth: `0`, 
            }} id='modalDescription'>
              This is a dialog window which overlays the main content of the page. The modal begins with a heading 1 called &quot;Interesting Modal Title&quot;. 
              Pressing the escape key or the close modal button at the top of the modal will close the modal and bring you back to where you were on the page.
            </div>

            <TcbColorSquares />
            
            <div css={{
              display: `grid`, 
              paddingTop: `0.25rem`,
              paddingBottom: `0.25rem`, 
              paddingLeft: `0.5rem`,
              paddingRight: `0.5rem`, 
              justifyContent: `flex-end`, 
              width: `100%`, 
            }}>
              <button autoFocus onClick={onClose} css={{
                padding: `0.5rem`, 
                color: `#4B5563`, 
                transitionProperty: `all`,
                transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                transitionDuration: `300ms`,
                backgroundColor: `transparent`,
                borderWidth: `0`,
                cursor: `pointer`,
                '&:hover': {
                  color: `#000000` 
                }
              }}>
                <span css={{
                  display: `inline-block`, 
                  paddingRight: `0.5rem`, 
                  fontSize: `12px`,

                }}>Close</span>

                <span css={{fontSize: `14px`}}><Icon icon={closeIcon} overrides={{verticalAlign: `-0.25rem !important`}}/></span>
              </button>
            </div>
            <div css={{
              padding: `2.5rem`
            }}>
              {children}
            </div>
            
            <TcbColorSquares />
          </div>
        </FocusTrap>
      </div>
    </Portal>
  )
}


export default Modal