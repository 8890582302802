// https://www.prisma.io/blog/fullstack-remix-prisma-mongodb-3-By5pmN5Nzo1v#open-a-portal

import { createPortal } from 'react-dom'
import { useState, useEffect } from 'react'



// generate a div with an ID and append that element to the documents body
const createWrapper = (wrapperId) => {
  const wrapper = document.createElement('div')
  wrapper.setAttribute('id', wrapperId)
  document.body.appendChild(wrapper)

  document.getElementById('main-content').setAttribute('aria-hidden', 'true')

  return wrapper
}


export const Portal = ({ children, wrapperId }) => {
  const [wrapper, setWrapper] = useState(null)

  useEffect(() => {
    // If an element with the provided id doesn't already exist, invoke 
    // the createWrapper function to create one.
    let element = document.getElementById(wrapperId)
    let created = false

    if (!element) {
      created = true
      element = createWrapper(wrapperId)
    }

    setWrapper(element)

    // when the portal component is unmounted this will destroy the element
    return () => {
      if (created && element?.parentNode) {
        element.parentNode.removeChild(element)
        document.getElementById('main-content').setAttribute('aria-hidden', 'false')
      }
    }
  }, [wrapperId])

  if (wrapper === null) return null

  // creates portal to the newly generated div. 
  return createPortal(children, wrapper)
}