
import React from "react"
import Container from "./container"
import FullWidthSection from "./fullWidthSection"
import SafeWidthSection from "./safeWidthSection"
import Block from "./block"

const Grid = ({ children }) => <>{children}</>

Grid.Container = Container
Grid.FullWidthSection = FullWidthSection
Grid.SafeWidthSection = SafeWidthSection
Grid.Block = Block

export default Grid