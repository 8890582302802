import React from 'react'
import PropTypes from 'prop-types'
import t from "../../../theme/tokens"


const SafeWidthSection = ({ 
  as: Component,
  children, 
  styles, 
  ...otherProps
}) => {

  return (
    <Component css={t.mq({
      gridColumn: `2/span 12`,
      height: `100%`,
      display: `grid`,
      gridTemplateColumns: `repeat(12, 1fr)`,
      gridTemplatRows: `auto`,
      gridAutoRows: `min-content`,
      rowGap: `0`,
      columnGap: t.grid.columnGap,
      ...styles
    })} {...otherProps}>
      {children}
    </Component>
  )
}

SafeWidthSection.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

SafeWidthSection.defaultProps = {
  as: 'div',
}

export default SafeWidthSection