import React from 'react'

const Button = ({ children, clickHandler, styles }) => {
  return (
    <button
      onClick={clickHandler} 
      css={{
        background: `none`,
        color: `inherit`,
        border: `none`,
        padding: `0`,
        font: `inherit`,
        cursor: `pointer`,
        outline: `inherit`,
        ...styles,
    }}>
        {children}
    </button>
  )
}

export default Button