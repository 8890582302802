import React, { useState, useRef, useEffect } from 'react';
import Modal from '../modal/modal';
import { useScrollLock } from '../../hooks/useScrollLock'
import Icon from '../icon';
import rightArrowIcon from '../../../theme/icons/right-carrot.svg'





const PhExternalLink = ({
  to, 
  children,
  headerText = "You're continuing to another website", 
  bodyText = "You're continuing to another website that The Claxton Bank doesn't own or operate. Its owner is solely responsible for the website's content, offerings and level of security, so please refer to the website's posted privacy policy and terms of use.", 
  linkText = "Continue to Website",
  emailLink = false
}) => {

  const [isOpen, setIsOpen] = React.useState(false)
  const { lockScroll, unlockScroll } = useScrollLock();
  const modalTrigger = useRef(null);


  const openModal = (e) => {
    e.preventDefault();
    lockScroll();
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
    unlockScroll();
    modalTrigger.current.focus();
  }


  return (
    <>
      <a ref={modalTrigger} onClick={(e) => openModal(e) } href={to}>
        {children}
      </a>
      <Modal open={isOpen} onClose={() => closeModal()}>

        <h1>{emailLink ? "You're continuing to an email client" : headerText}</h1>
        <p>{emailLink ? "Because there is a risk that information transmitted via email could fall into the wrong hands, TCB suggests that confidential information, such as account numbers or social security numbers, not be transmitted via email. If this information must be stated, please contact us by phone or at your nearest office." : bodyText}</p>
        
        {/* --
        <SectionHeader>{headerText}</SectionHeader>
        <Body>{bodyText}</Body>
        --*/}

        <div css={{
          marginBottom: `1.5rem`, 
          marginTop: `3rem`, 
          textAlign: `right`, 
        }}>
          <button onClick={() => closeModal()} css={{
            marginRight: `0.75rem`, 
            color: `#F87171`, 
            transitionProperty: `all`,
            transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
            transitionDuration: `300ms`, 
            fontSize: `24px`,
            lineHeight: `24px`,
            backgroundColor: `transparent`,
                borderWidth: `0`,
            '&:hover': {
              cursor: `pointer`,
              color: `#991b1b`
            }
          }}>
            <span css={{
              display: `inline-block`, 
              paddingLeft: `0.25rem`, 
              verticalAlign: `middle`, 
              fontSize: `16px`,
            }}>Return to The Claxton Bank</span>
          </button>
          <a href={to} css={{
            paddingTop: `0.75rem`,
            paddingBottom: `0.75rem`, 
            paddingLeft: `1.5rem`,
            paddingRight: `1.5rem`, 
            marginLeft: `0.75rem`, 
            borderRadius: `0.375rem`, 
            color: `#ffffff !important`, 
            transitionProperty: `all`,
            transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
            transitionDuration: `300ms`, 
            fontSize: `24px`,
            backgroundColor: `rgb(3, 118, 189)`,
            '&:hover': {
              backgroundColor: `rgb(0, 94, 168)`,
            }
          }}>
            <span css={{
              display: `inline-block`, 
              paddingRight: `0.75rem`, 
              verticalAlign: `middle`, 
              fontSize: `16px`,
            }}>{emailLink ? 'Proceed to email client' : linkText}</span>
            <span css={{fontSize: `17px`,}}>
             <Icon icon={rightArrowIcon} overrides={{verticalAlign: `-0.15rem !important`}}/>
            </span>
          </a>
        </div>

      </Modal>
    </>
  )
}

export default PhExternalLink














