import t from "../../../theme/tokens"
import styled from '@emotion/styled'

const styleReset = {
  margin: `0`,
  padding: `0`,
  fontSize: `100%`,
  verticalAlign: `baseline`
}

const mqOne = `@media (min-width: ${t.breakpoints[0]}px)`
const mqTwo = `@media (min-width: ${t.breakpoints[4]}px)`


export const SizedText = styled.h1(props => ({
  ...styleReset,
  fontSize: `${props.smallSize}rem`,
  ...props.styleOverrides,
  [mqOne]: {
    fontSize: `calc(${props.smallSize}rem + (${props.largeSize * 16} - ${props.smallSize * 16})*(100vw - ${t.breakpoints[0]}px) / (${t.breakpoints[4]} - ${t.breakpoints[0]}))`,
    lineHeight: `1`,
  },
  [mqTwo]: {
    fontSize: `${props.largeSize}rem`
  }
}))



export const Canon = styled.h1(props => ({
  ...styleReset,
  fontSize: `${t.canon.fontSize[0]}rem`,
  color: `#182852`,
  marginBottom: `3rem`,
  ...props.styleOverrides,
  lineHeight: `1`,
  [mqOne]: {
    fontSize: `calc(${t.canon.fontSize[0]}rem + (${t.canon.fontSize[1] * 16} - ${t.canon.fontSize[0] * 16})*(100vw - ${t.breakpoints[0]}px) / (${t.breakpoints[4]} - ${t.breakpoints[0]}))`,
    lineHeight: `1`,
  },
  [mqTwo]: {
    fontSize: `${t.canon.fontSize[1]}rem`
  }
}))


export const Body = styled.p(props => ({
  ...styleReset,
  fontFamily: t.body.fontFamily,
  fontWeight: t.body.fontWeight,
  fontSize: t.body.fontSize,
  lineHeight: t.body.lineHeight,
  color: t.body.color,
  hyphens: `auto`,
  ...props.styleOverrides,
}))