import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import Footer from './footer'


export default function FooterContainer(props) {
  return (
    <StaticQuery
      query={graphql`
      query FooterQuery {
        menusSitenavigationmenuYaml(menuName: {eq: "Site Navigation Menu"}) {
          menuName
          menuItems {
            longText
            path
            subMenu {
              path
              longText
            }
          }
        }
      }
      `}
      render={data => <Footer data={data} {...props} />}
    />
  )
}
















