
import React from 'react'
import PropTypes from 'prop-types'
import t from "../../../theme/tokens"


const Container = ({ 
  as: Component,
  children, 
  styles,
  ...otherProps
 }) => {

  return (
    <Component css={t.mq({
      height: `100%`,
      display: `grid`,
      gridTemplateColumns: `
        [full-start]
          minmax(1rem, 1fr) 
            [main-strart]
              repeat(${t.grid.columns}, minmax(auto, ${t.grid.maxWidth})) 
            [main-end]
          minmax(1rem, 1fr)
        [full-end]
      `,
      gridTemplatRows: `auto`,
      gridAutoRows: `min-content`,
      rowGap: `0`,
      columnGap: t.grid.columnGap,
      ...styles,
    })} {...otherProps}>
      {children}
    </Component>
  )
}

Container.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

Container.defaultProps = {
  as: 'div',
}

export default Container