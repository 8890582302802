import facepaint from 'facepaint'

const mobilePortrait = 414
const mobileLandscape = 736
const tabletPortrait = 834
const tabletLandscape = 1024
const desktop = 1495
const largeScreen = 1570

const breakpoints = [mobilePortrait, mobileLandscape, tabletPortrait, tabletLandscape, desktop, largeScreen]



//const mobile = 650
//const tablet = 768
//const desktop = 1495
//const largeScreen = 1570
//const breakpoints = [mobile, tablet, desktop, largeScreen ]


const mq = facepaint(
  breakpoints.map(bp => `@media (min-width: ${bp}px)`)
)

const tokensExport = {
  breakpoints: breakpoints,
  mq: mq,
}

export default tokensExport
