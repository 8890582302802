import typography from "./tokens/typography"
import grid from "./tokens/grid"
import breakpoints from "./tokens/breakpoints"


const tokens = {
  ...breakpoints,
  ...typography,
  ...grid,
}

export default tokens