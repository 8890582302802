
// This is the root font size and line height that is used in the html and body tags and will be used
// as the basis for modular scale and fluid typography
const baseFontSize = `16px`
const baseLineHeight = `1.5`

const typefaces = {
  sourceSansPro: `source-sans-pro, "Segoe UI", Tahoma, Geneva, sans-serif`,
  proximaNova: `proxima-nova, "Lucida Sans Unicode", "Lucida Grande", sans-serif`,
}

const fontWeights = {
  normal: `400`,
  semiBold: `600`,
}

const fontSizes = {
  cannon: [2.027, 3.247],
}




// --- EXPORTS --- //
const typography = {
  baseFontSize: baseFontSize,
  baseLineHeight: baseLineHeight,
  baseTypeface: typefaces.sourceSansPro,
  fontWeights: fontWeights,
  canon: {
    fontSize: fontSizes.cannon,
  },
  body: {
    fontFamily: typefaces.sourceSansPro,
    fontWeight: fontWeights.normal,
    fontSize: `1rem`,
    lineHeight: `1.5`,
    color: `#707070`
  },
}

export default typography