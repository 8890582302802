import React from "react"
import { Global, css } from '@emotion/react'
import t from "../tokens.js"

const CssReset = () => {
  return (
    <>
      <Global
      styles={css`
        html {
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: transparent;
          box-sizing: border-box;
          overflow: -moz-scrollbars-vertical;
          background-color: #e9eaf1;
          font-size: ${t.baseFontSize};
          line-height: ${t.baseLineHeight};
          font-family: source-sans-pro, "Segoe UI", Tahoma, Geneva, sans-serif;
        }
        body {
          margin: 0;
          padding: 0;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          overflow-y: scroll;
          font-family: source-sans-pro, "Segoe UI", Tahoma, Geneva, sans-serif;
        }
        *,
        *:before,
        *:after {
          box-sizing: inherit;
          /*-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;*/
        }
        .no-outline-on-focus button:focus,
        .no-outline-on-focus
        input:focus
        .no-outline-on-focus
        select:focus
        .no-outline-on-focus
        textarea:focus
        .no-outline-on-focus
        a:focus {
          outline: none;
        }
        input[type="text"],
        input[type="password"] {
          -webkit-appearance: textfield; /* 1 */
          -moz-box-sizing: content-box;
          -webkit-box-sizing: content-box; /* 2 */
          box-sizing: content-box;
        }
        a:link, a:visited {
          color: blue;
        }
}

      `}
    />
    </>
  )
}

export default CssReset