
const numberOfColumns = `12`
const maxWidth = `7.2rem`
const rowGap = `0`
const columnGap = [`0.5rem`, `0.5rem`, `1rem`, `4rem`]

const grid ={
  grid: {
    columns: numberOfColumns,
    maxWidth: maxWidth,
    rowGap: rowGap,
    columnGap: columnGap
  }
}


export default grid
