import React from 'react'
import PropTypes from 'prop-types'
import t from "../../../theme/tokens"

const FullWidthSection = ({
  as: Component,
  children,
  styles,
  ...otherProps
}) => {
  return (
    <Component {...otherProps}
    css={t.mq({
      gridColumn: `1/span 14`,
      height: `100%`,
      display: `grid`,
      gridTemplateColumns: `
        [full-start]
          minmax(1rem, 1fr) 
            [main-strart]
              repeat(${t.grid.columns}, minmax(auto, ${t.grid.maxWidth})) 
            [main-end]
          minmax(1rem, 1fr)
        [full-end]
      `,
      gridTemplatRows: `auto`,
      gridAutoRows: `min-content`,
      rowGap: `0`,
      columnGap: t.grid.columnGap,
      ...styles
    })}>
      {children}</Component>
  )
}

FullWidthSection.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

FullWidthSection.defaultProps = {
  as: 'div',
}

export default FullWidthSection