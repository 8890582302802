import React, { useState } from 'react';
import Icon from './icon';
import chatIcon from "../../theme/icons/chat-button.svg"

export default function ChatButton() {


  const openInNewTab = (e, url) => {
    e.preventDefault()
    window.open(url, 'testWindow', `
    toolbar=no,
    location=no,
    status=no,
    menubar=no,
    scrollbars=yes,
    resizable=yes,
    width=520,
    height=800`);
    return false
  };


  return (
    <div css={{
      bottom: `20px`,
      right: `20px`,
      '@media (min-width: 734px)': { 
        bottom: `30px`,
        right: `30px`,
      }, 
      position: `fixed`,
      zIndex: `99999999999999999`
    }}>
      <a
        href=""
        css={{
          display: `block`,
          lineHeight: `1`,
          margin: `0`,
          padding: `0.75rem`,
          backgroundColor: `#3498db`,
          border: `1px solid #6B7797`,
          borderRadius: `50%`,
          color: `#fff !important`,
          fill: `#fff !important`,
          boxShadow: `4px 4px 15px rgba(0, 0, 0, 0.5)`, 
          transition: `background-color 0.5s ease`,
          '@media (min-width: 734px)': { padding: `1rem`, } ,
          '&:hover': {
            backgroundColor: `#48a8dc`
          }
        }}
        onClick={(e) => openInNewTab(e, 'https://videobanking.tcbga.bank/embed/invochat')}
      >
        <span css={{
          fontSize: `1.802rem`,
          '@media (min-width: 734px)': { fontSize: `2.281rem`, }            
        }}>
          <Icon icon={chatIcon} />
        </span>
      </a>
    </div>
  );
}

