import React from "react"
import PropTypes from 'prop-types'

const Flex = ({ as: Component, ...props}) => (
  <Component
  css={{
      display: props.container ? "flex" : "block",
      justifyContent: props.justifyContent || "flex-start",
      flexDirection: props.flexDirection || "row",
      flexGrow: props.flexGrow || 0,
      flexBasis: props.flexBasis || "auto",
      flexShrink: props.flexShrink || 1,
      flexWrap: props.flexWrap || "nowrap",
      flex: props.flex || "0 1 auto",
      alignItems: props.alignItems || "stretch",
      margin: props.margin || "0",
      padding: props.padding || "0",
      width: props.width || "auto",
      height: props.height || "auto",
      maxWidth: props.maxWidth || "none",
      ...props.styles,
    }}
    >
      {props.children}
    </Component>
)

Flex.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /**
    This defines a flex container; inline or block depending on the given value. It enables a flex context for all its direct children.
    CSS value applied with be "flex" if container is true or "block" if container is false.
  */
  container: PropTypes.bool,
  /** 
    This defines the alignment along the main axis. It helps distribute extra free space leftover when either all the flex items on a line are inflexible, or are flexible but have reached their maximum size. It also exerts some control over the alignment of items when they overflow the line.
    <ul>
      <li><b>flex-start (default):</b> items are packed toward the start of the flex-direction.</li>
      <li><b>flex-end:</b> items are packed toward the end of the flex-direction.</li>
      <li><b>start:</b> items are packed toward the start of the writing-mode direction.</li>
      <li><b>end:</b> items are packed toward the end of the writing-mode direction.</li>
      <li><b>left:</b> items are packed toward left edge of the container, unless that doesn’t make sense with the flex-direction, then it behaves like start.</li>
      <li><b>right:</b> items are packed toward right edge of the container, unless that doesn’t make sense with the flex-direction, then it behaves like start.</li>
      <li><b>center:</b> items are centered along the line</li>
      <li><b>space-between:</b> items are evenly distributed in the line; first item is on the start line, last item on the end line</li>
      <li><b>space-around:</b> items are evenly distributed in the line with equal space around them. Note that visually the spaces aren’t equal, since all the items have equal space on both sides. The first item will have one unit of space against the container edge, but two units of space between the next item because that next item has its own spacing that applies.</li>
      <li><b>space-evenly:</b> items are distributed so that the spacing between any two items (and the space to the edges) is equal.</li>
    </ul>
    Note that that browser support for these values is nuanced. For example, space-between never got support from some versions of Edge, and start/end/left/right aren’t in Chrome yet. MDN has detailed charts. The safest values are flex-start, flex-end, and center.<br /><br />
    There are also two additional keywords you can pair with these values: safe and unsafe. Using safe ensures that however you do this type of positioning, you can’t push an element such that it renders off-screen (e.g. off the top) in such a way the content can’t be scrolled too (called “data loss”).
  */
  justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', 'space-evenly']),
  /**
    This establishes the main-axis, thus defining the direction flex items are placed in the flex container. Flexbox is (aside from optional wrapping) a single-direction layout concept. Think of flex items as primarily laying out either in horizontal rows or vertical columns.
    <ul>
      <li><b>row (default):</b> left to right in ltr; right to left in rtl</li>
      <li><b>row-reverse:</b> right to left in ltr; left to right in rtl</li>
      <li><b>column:</b> same as row but top to bottom</li>
      <li><b>column-reverse:</b> same as row-reverse but bottom to top</li>
    </ul>
  */
  flexDirection: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  /**
    This defines the ability for a flex item to grow if necessary. It accepts a unitless value that serves as a proportion. It dictates what amount of the available space inside the flex container the item should take up.<br /><br />
    If all items have flex-grow set to 1, the remaining space in the container will be distributed equally to all children. If one of the children has a value of 2, the remaining space would take up twice as much space as the others (or it will try to, at least).<br /><br />
    <em>Negative numbers are invalid.</em>
  */
  flexGrow: PropTypes.number,
  /**
    This defines the default size of an element before the remaining space is distributed. It can be a length (e.g. 20%, 5rem, etc.) or a keyword. The auto keyword means “look at my width or height property” (which was temporarily done by the main-size keyword until deprecated). The content keyword means “size it based on the item’s content” – this keyword isn’t well supported yet, so it’s hard to test and harder to know what its brethren max-content, min-content, and fit-content do.<br /><br />
    If set to 0, the extra space around content isn’t factored in. If set to auto, the extra space is distributed based on its flex-grow value. <a href="https://www.w3.org/TR/css-flexbox-1/images/rel-vs-abs-flex.svg" target="_blank">See this graphic.</a>
  */
  flexBasis: PropTypes.string,
  /**
    This defines the ability for a flex item to shrink if necessary.<br /><br />
    <em>Negative numbers are invalid.</em>
  */
  flexShrink: PropTypes.number,
  /**
    By default, flex items will all try to fit onto one line. You can change that and allow the items to wrap as needed with this property.
    <ul>
      <li><b>nowrap (default):</b> all flex items will be on one line</li>
      <li><b>wrap:</b> flex items will wrap onto multiple lines, from top to bottom.</li>
      <li><b>wrap-reverse:</b> flex items will wrap onto multiple lines from bottom to top.</li>
    </ul>
    There are some <a href="https://css-tricks.com/almanac/properties/f/flex-wrap/" target-"_blank">visual demos of flex-wrap here</a>.
  */
  flexWrap: PropTypes.oneOf(['nowrap', 'wrap', 'wrap-reverse']),
  /**
    This is the shorthand for flex-grow, flex-shrink and flex-basis combined. The second and third parameters (flex-shrink and flex-basis) are optional. The default is 0 1 auto, but if you set it with a single number value, it’s like 1 0.
  */
  flex: PropTypes.string,
  /**
    This defines the default behavior for how flex items are laid out along the cross axis on the current line. Think of it as the justify-content version for the cross-axis (perpendicular to the main-axis).
    <ul>
      <li><b>stretch (default):</b> stretch to fill the container (still respect min-width/max-width)</li>
      <li><b>flex-start:</b> items are placed at the start of the cross axis. The difference between these is subtle, and is about respecting the flex-direction rules or the writing-mode rules.</li>
      <li><b>flex-end:</b> items are placed at the end of the cross axis. The difference again is subtle and is about respecting flex-direction rules vs. writing-mode rules.</li>
      <li><b>center:</b> items are centered in the cross-axis</li>
      <li><b>baseline:</b> items are aligned such as their baselines align</li>
    </ul>
    The safe and unsafe modifier keywords can be used in conjunction with all the rest of these keywords (although note browser support), and deal with helping you prevent aligning elements such that the content becomes inaccessible.
  */
  alignItems: PropTypes.oneOf(['stretch', 'flex-start', 'flex-end', 'center', 'baseline']),
  margin: PropTypes.string,
  padding: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  maxWidth: PropTypes.string,
  styles: PropTypes.object,
}

Flex.defaultProps = {
  as: 'div',
  container: false,
  justifyContent: "flex-start",
  flexDirection: "row",
  flexGrow: 0,
  flexBasis: "auto",
  flexShrink: 1,
  flexWrap: "nowrap",
  flex: "0 1 auto",
  alignItems: "stretch",
  margin: "0",
  padding: "0",
  width: "auto",
  height: "auto",
  maxWidth: "none",
}


export default Flex
