import React from 'react'

const TcbColorSquares = () => {
  return (
    <div css={{
      display: `flex`, 
      width: `100%`, 
      height: `0.75rem`, 

    }} aria-hidden="true">
      <div css={{width: `25%`, height: `100%`, backgroundColor: `rgb(0, 180, 229)` }}></div>
      <div css={{width: `25%`, height: `100%`, backgroundColor: `rgb(3, 118, 189)` }}></div>
      <div css={{width: `25%`, height: `100%`, backgroundColor: `rgb(0, 94, 168)` }}></div>
      <div css={{width: `25%`, height: `100%`, backgroundColor: `rgb(24, 40, 82)` }}></div>
    </div>
  )
}

export default TcbColorSquares