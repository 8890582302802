import React from 'react'
import { StaticQuery, graphql } from "gatsby"

import MobileNavMenu from './mobileNavMenu'


export default function MobileNavMenuContainer(props) {
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          menusSitenavigationmenuYaml(menuName: {eq: "Site Navigation Menu"}) {
            menuItems {
              longText
              path
              subMenu {
                path
                longText
              }
            }
          }
        }
      `}
      render={data => <MobileNavMenu data={data} {...props} />}
    />
  )
}
