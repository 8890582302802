import React, { useContext } from "react"
import { AlertContext } from "./alertContext"

import { css, keyframes } from "@emotion/react"

import Grid from "../grid"
import Icon from "../icon"

import infoIcon from "../../../theme/icons/info.svg"
import closeIcon from "../../../theme/icons/close.svg"

const Alert = ({ type, enabled, children }) => {
  const [context, setContext] = useContext(AlertContext)

  return (
    <div
      css={{
        padding: `0.25rem 0`,
        backgroundColor: type === "Danger" ? "#f87171" : `#f2f6fa`,
        borderBottom:
          type === "Danger" ? "3px solid #991b1b" : `3px solid #0e356c`,
        borderTop:
          type === "Danger" ? "3px solid #991b1b" : `3px solid #0e356c`,
        overflow: `hidden`,
        display: context ? `block` : `none`,
        color: `#450a0a`,
      }}
    >
      <Grid.FullWidthSection>
        <Grid.Block start="2" span="13">
          <div
            css={{
              display: `flex`,
              alignItems: `center`,
            }}
          >
            <div css={{ paddingRight: `1.5rem`, fontSize: `1.602rem` }}>
              <Icon
                icon={infoIcon}
                overrides={{ verticalAlign: `-0.25rem !important` }}
              />
            </div>
            <div>
              <p css={{ fontSize: `0.889rem` }}>{children}</p>
            </div>
            <div
              css={{
                paddingLeft: `2.5rem`,
                marginRight: `0.5rem`,
                fontSize: `1.42rem`,
                alignSelf: `flex-start`,
              }}
            >
              <button
                onClick={() => setContext(false)}
                css={{
                  border: `none`,
                  background: `none`,
                  cursor: `pointer`,
                }}
              >
                <Icon
                  icon={closeIcon}
                  overrides={{ verticalAlign: `-0.25rem !important` }}
                />
              </button>
            </div>
          </div>
        </Grid.Block>
      </Grid.FullWidthSection>
    </div>
  )
}

export default Alert
