import React from 'react'
import PropTypes from 'prop-types'
import t from "../../../theme/tokens"


const Block = ({ 
  as: Component,
  start, 
  span, 
  children, 
  styles,
  ...otherProps 
}) => {

  let endValue = span ? `span ${span}` : `span 1`
  if(Array.isArray(span)) {
    endValue = span.map(s => `span ${s}`)
  }
  
  return (
    <Component css={t.mq({
      gridColumnStart: start,
      gridColumnEnd: endValue,
      ...styles,
    })} {...otherProps}>
      {children}
    </Component>
  )
}

Block.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}

Block.defaultProps = {
  as: 'div',
}


export default Block