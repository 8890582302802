import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import Icon from '../../elements/icon'
import QuickLinksMenu from '../menus/quickLinksMenu'

import carrotIcon from "../../../theme/icons/right-carrot.svg"
import userIcon from "../../../theme/icons/user.svg"


const DropdownMenu = ({}) => (
  <div css={{
    position: `absolute`,
    //marginLeft: `-2rem`,
    paddingTop: `0.25rem`,
  }}>
    <div css={{
      position: `relative`,
      marginLeft: `80px`,
      width: `0`, 
      height: `0`, 
      borderLeft: `11px solid transparent`,
      borderRight: `11px solid transparent`,
      borderBottom: `11px solid lightgray`,
      zIndex: `99`,
    }}></div>
    <div css={{
      position: `relative`,
      marginLeft: `82px`,
      marginTop: `-9px`,
      width: `0`, 
      height: `0`, 
      borderLeft: `9px solid transparent`,
      borderRight: `9px solid transparent`,
      borderBottom: `9px solid #fff`,
      zIndex: `999`,
    }}></div>
    <div css={{
      position: `relative`,
      zIndex: `9`,
      marginTop: `-1px`,
      backgroundColor: `#fff`,
      border: `1px solid lightgray`,
      borderRadius: `0.25rem`,
      boxShadow: `5px 5px 15px 5px rgba(0,0,0,0.2)`,
      'a': {
        margin: `0`,
        display: `block`,
        fontSize: `0.85rem`,
        padding: `0.5rem 1.5rem`,
        borderBottom: `1px solid lightgray`,
        '&:hover': {
          backgroundColor: `lightgray`,
        }
      }
    }}>
      <Link to="/login">Digital Banking</Link>
      <a href="https://tcb.msird.com/">Merchant WebRDC</a>
      <a href="https://theclaxtonbank.csinufund.com/" css={{borderBottom: `none !important`}}>NuFund New Account</a>
    </div>
  </div>
)


const DesktopNavMenuContent = ({ data }) => {

  const menuItems = data.menusSitenavigationmenuYaml.menuItems.map(i => (
    <Link to={i.path} partiallyActive={true} activeClassName="active">
      <span>{i.shortText}</span>
      <span className="downCarrot"><Icon icon={carrotIcon} overrides={{verticalAlign: `-0.18em !important`, width: `1.2em !important`, height: `1.2em !important`, transform: `rotate(90deg)`}}/></span>
    </Link>
  ))

  return (
    <>
    
    <nav 
    css={{
      display: `none`,
      'a': {
        fontSize: `1.2rem`,
        textDecoration: `none`,
        padding: `0.25rem 0.25rem`,
        marginRight: `1rem`,
        color: `#2c3e50`,
        transition: `all .25s ease-in-out`,
        '.downCarrot': {fontSize: `0.8rem`, padding: `0 0.5rem`, visibility:'hidden' },
        '&:hover': {
          //textShadow: `0px 0px 1px black`,
        },
        '&.active': {
          //fontWeight: `600`,
          textShadow: `0px 0px 1px black`,
          //borderBottom: `2px solid #000`,
          '& span': {visibility:'visible !important' }
        }
      },
      'a.login': {
        paddingLeft: `2rem`,
        marginRight: `0`
      },
      '@media (min-width: 1080px)': {display: `block`}
    }}
  >
    {menuItems}
    <div
      css={{
        display: `inline-block`, 
        borderLeft: `1px solid #bdc3c7`, 
        cursor: `pointer`,
        '.loginDropdown': { 
          transition: `0.3s`,
          opacity: `0`,
        },
        '&:hover': {
          '.loginDropdown': {
            opacity: `1`,
            transitionDelay: `0.8s`,
          },
        }
      }}>
        <Link className="login" 
          to="/login"
        >
          <span css={{fontSize: `1.4rem`, padding: `0 0.5rem  0`}}><Icon icon={userIcon} overrides={{verticalAlign: `-0.35em !important`, width: `1.2em !important`, height: `1.2em !important`}}/></span>
          <span>Log In</span>
          <span css={{fontSize: `0.8rem`, padding: `0 0  0 0.5rem`}}><Icon icon={carrotIcon} overrides={{verticalAlign: `-0.18em !important`, width: `1.2em !important`, height: `1.2em !important`}}/></span>
        </Link>
        <div className="loginDropdown">
          <DropdownMenu />
        </div>
      </div>
  </nav>
  </>
  )
}



export default function DesktopNavMenu(props) {
  return (
    <StaticQuery
      query={graphql`
        query DesktopNavQuery {
          menusSitenavigationmenuYaml(menuName: {eq: "Site Navigation Menu"}) {
            menuItems {
              shortText
              path
            }
          }
        }
      `}
      render={data => <DesktopNavMenuContent data={data} {...props} />}
    />
  )
}
