import React from "react"

const Icon = ({ icon, label, color, overrides }) => (
  <svg
    css={{
      display: `inline-block`,
      width: `1em`,
      height: `1em`,
      fontSize: `inherit`,
      color: color || `inherit`,
      verticalAlign: `-0.125em`,
      fill: color || `currentColor`,
      ...overrides
    }} 
    aria-label={label}
    focusable="false"
    viewBox={icon.viewBox}
  >
    <use xlinkHref={icon.url} aria-hidden="true"/>
  </svg>
)

export default Icon