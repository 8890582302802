import React, { useState } from 'react'
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"


const SectionNavMenuContent = ({ data, isSubMenuOpen, openAccountLink }) => {
  const location = useLocation()
  const currentTLP = `/${location.pathname.split('/')[1]}`
  const [activePath, setActivePath ] = useState(currentTLP || '/')


  return (
    <nav css={{
      display: isSubMenuOpen === true ? `block`:`none`,
      borderTop: `1px solid #bdc3c7`,
      textAlign: `center`,
      'a': {
        textDecoration: `none`,
        padding: `0.55rem`,
        margin: `0 0.5rem`,
        display: `inline-block`,
        color: `#34495e`,
        transition: `all .25s ease-in-out`,
        '.subActive, &:hover': {
          textShadow: `0px 0px 1px black`,
          //color: `#2980b9`,
        },
        '.subActive': {
          color: `red`
        }
      }
    }}>
      {data.menusSitenavigationmenuYaml.menuItems.filter(parent => parent.path === activePath).map(i => (
        <>
          {i.subMenu.map(l => (
            <Link partiallyActive={true} activeStyle={{ textShadow: `0px 0px 1px black`, }} to={l.path}>{l.shortText}</Link>
          ))}
        </>
      ))}
      {openAccountLink && 
        <a css={{}} href={"https://theclaxtonbank.csinufund.com/"}>Open an Account</a>
      }
    </nav>
  )
}



export default function SectionNavMenu(props) {
  return (
    <StaticQuery
      query={graphql`
        query SectionNavQuery {
          menusSitenavigationmenuYaml(menuName: {eq: "Site Navigation Menu"}) {
            menuItems {
              path
              subMenu {
                path
                shortText
              }
            }
          }
        }
      `}
      render={data => <SectionNavMenuContent data={data} {...props} />}
    />
  )
}
